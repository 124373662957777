type RoutesType = {
  [name: string]: string;
};

export const ROUTES: RoutesType = {
  AUTH: "/auth",
  INTERNAL_SCREENS: "/internal",
  LOGIN: `/auth/login`,
  INTERNAL_PROJECTS: "/internal/projects",
  PROJECTS: "/home"
};

export const BACKEND_URL = "https:";

export const CONTACT_SALES_URL =
  "https://murf.ai/murf-dub?form=popup&utm_source=dub_platform";
