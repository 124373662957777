import {
  ExpandLess,
  ExpandMore,
  FolderSpecialRounded,
  SettingsRounded,
  UpgradeRounded
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { QUERY_PARAMS_FILTERS, STATUS_FILTER } from "./constants";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { IClientProjectHomeData } from "@/features/clientHome";
import CreditsIcon from "@/components/assets/CreditsIcon";
import {
  setActiveStep,
  setCheckoutPlan,
  setPricingDialog
} from "@/reducers/slices/pricingDialogSlice/pricingDialogSlice";
import { trackMixpanelEvent } from "@/utils/mixpanel";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { STATUS } from "@/constants/status";
import { PLANS_CATEGORY, PRICING_DIALOG_STEP } from "@/features/pricing/types";
import { batch } from "react-redux";
import { CONTACT_SALES_URL } from "@/constants/routeUrls";

const EMPTY_ARRAY: IClientProjectHomeData[] = [];

const ClientSideBar = (): React.ReactNode => {
  const navigate = useNavigate();
  const [expandProjectsMenu, setExpandProjectsMenu] = useState(true);
  const [expandSettingsMenu, setExpandSettingsMenu] = useState(true);

  const location = useLocation();
  const dispatch = useAppDispatch();

  const currentActiveTab = useMemo(() => {
    const _search = new URLSearchParams(location.search);
    if (_search.get(STATUS_FILTER)?.length) {
      return _search.get(STATUS_FILTER);
    }
    if (location.pathname.startsWith("/profile/workspace/credit-utilization")) {
      return "CREDIT_UTILIZATION";
    }
    if (location.pathname.startsWith("/profile/workspace/manage")) {
      return "WORKSPACE_MANAGE";
    }
    if (location.pathname.startsWith("/profile/workspace")) {
      return "WORKSPACE";
    }
    if (location.pathname.startsWith("/profile/user")) {
      return "PROFILE_USER";
    }

    return null;
  }, [location]);

  const _allProjects = useTypedSelector(
    (state) => state.clientHome.allProjects
  );

  const workspaceApiStatus = useTypedSelector(
    (state) => state.user.workspaceApiStatus
  );
  const { usedCredits, assignedCredits } = useTypedSelector(
    (state) => state.user.activeWorkspaceData || ({} as any)
  );

  const userPlan = useTypedSelector(
    (state) => state.workspace?.workspacePlan?.planDetails
  );

  const planDetails = useTypedSelector(
    (state) => state.workspace.workspacePlan?.planDetails
  );

  const isIcpUser = useTypedSelector((state) => state.globalState.isICPUser);

  const planCategory = planDetails?.category;

  const availableCredits = Math.max(
    0,
    assignedCredits ? (assignedCredits - usedCredits) / assignedCredits : 0
  );

  const allProjects = useMemo(() => {
    if (_allProjects) {
      return Object.values(_allProjects);
    }
    return EMPTY_ARRAY;
  }, [_allProjects]);

  const unArchivedData = useMemo(() => {
    return allProjects.filter((data) => !data.archived);
  }, [allProjects]);

  const draftCount = useMemo(() => {
    return unArchivedData.filter((data) => data.draft)?.length || 0;
  }, [unArchivedData]);

  const finishedCount = useMemo(() => {
    return (
      unArchivedData.filter(
        (data) => data.finished && data.totalDubs === data.finished
      )?.length || 0
    );
  }, [unArchivedData]);

  const pendingReviewCount = useMemo(() => {
    return (
      unArchivedData.filter(
        (data) => data.waitingForApproval || data.uploadFailed
      )?.length || 0
    );
  }, [unArchivedData]);

  const onGoingCount = useMemo(() => {
    return (
      unArchivedData?.length - (draftCount + finishedCount + pendingReviewCount)
    );
  }, [unArchivedData, draftCount, finishedCount, pendingReviewCount]);

  const archivedCount = useMemo(() => {
    return allProjects.filter((project) => project.archived)?.length || 0;
  }, [allProjects]);

  const shouldShowAutoPayFailedError = useMemo(() => {
    if (userPlan?.autoPayEnabled && userPlan?.autoPayStatus === "FAILED") {
      return true;
    }
    return false;
  }, [userPlan]);

  const onContactSales = () => {
    trackMixpanelEvent(MIXPANEL_EVENTS.CONTACT_SALES, {
      "Entry Point": "Home Side Bar"
    });
    window.open(CONTACT_SALES_URL, "_blank");
  };

  const handleUpgrade = () => {
    if (planCategory === PLANS_CATEGORY.CUSTOM) {
      onContactSales();
      return;
    }
    if (planCategory === PLANS_CATEGORY.PAY_AS_YOU_GO) {
      batch(() => {
        if (planDetails) {
          dispatch(
            setCheckoutPlan({
              planId: planDetails.planId,
              category: planDetails.category,
              entryPoint: "Sidebar",
              maxUser: planDetails.maxUser,
              noOfProject: planDetails.noOfProject,
              noWaterMark: planDetails.noWaterMark,
              qualityAssurance: planDetails.qualityAssurance,
              selfEditAllowed: planDetails.selfEditAllowed
            })
          );
        }
        dispatch(setActiveStep(PRICING_DIALOG_STEP.SETUP_FUNDS));
      });
    }
    dispatch(setPricingDialog(true));
    trackMixpanelEvent(MIXPANEL_EVENTS.UPGRADE_START, {
      "Entry Point": "Sidebar"
    });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        minWidth: 316,
        borderRadius: 0,
        borderRight: 1,
        borderColor: "divider",
        position: "relative"
      }}
      aria-label="Filter projects or visit settings page"
      role="complementary"
    >
      <Stack
        height={"calc(100% - 64px)"}
        justifyContent={"space-between"}
        p={1}
        sx={{
          maxHeight: "-webkit-fill-available",
          overflow: "auto"
        }}
      >
        <Stack>
          <ListItemButton
            onClick={() => setExpandProjectsMenu((state) => !state)}
            disableGutters
            sx={{
              px: 2,
              py: 1,
              gap: 1
            }}
          >
            <FolderSpecialRounded color="secondary" />
            <ListItemText primary="Projects" />
            {expandProjectsMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={expandProjectsMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense>
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => {
                  navigate("/home");
                }}
                selected={!currentActiveTab}
              >
                <ListItemText
                  primary={`All Projects (${allProjects?.length || 0})`}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => {
                  navigate(
                    `/home?${STATUS_FILTER}=${QUERY_PARAMS_FILTERS.DRAFT}`
                  );
                }}
                selected={currentActiveTab === QUERY_PARAMS_FILTERS.DRAFT}
              >
                <ListItemText primary={`Draft (${draftCount})`} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => {
                  navigate(
                    `/home?${STATUS_FILTER}=${QUERY_PARAMS_FILTERS.ON_GOING}`
                  );
                }}
                selected={currentActiveTab === QUERY_PARAMS_FILTERS.ON_GOING}
              >
                <ListItemText primary={`On-going (${onGoingCount})`} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => {
                  navigate(
                    `/home?${STATUS_FILTER}=${QUERY_PARAMS_FILTERS.PENDING_REVIEW}`
                  );
                }}
                selected={
                  currentActiveTab === QUERY_PARAMS_FILTERS.PENDING_REVIEW
                }
              >
                <ListItemText
                  primary={`Pending Review (${pendingReviewCount})`}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => {
                  navigate(
                    `/home?${STATUS_FILTER}=${QUERY_PARAMS_FILTERS.FINISHED}`
                  );
                }}
                selected={currentActiveTab === QUERY_PARAMS_FILTERS.FINISHED}
              >
                <ListItemText primary={`Finished (${finishedCount})`} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => {
                  navigate(
                    `/home?${STATUS_FILTER}=${QUERY_PARAMS_FILTERS.ARCHIVED}`
                  );
                }}
                selected={currentActiveTab === QUERY_PARAMS_FILTERS.ARCHIVED}
              >
                <ListItemText primary={`Archived (${archivedCount})`} />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            onClick={() => setExpandSettingsMenu((state) => !state)}
            disableGutters
            sx={{
              px: 2,
              py: 1,
              gap: 1,
              mt: 3
            }}
          >
            <SettingsRounded color="secondary" />
            <ListItemText primary="Settings" />
            {expandProjectsMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={expandSettingsMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense>
              <ListItemButton
                href="/profile/workspace"
                sx={{ pl: 6 }}
                selected={currentActiveTab === "WORKSPACE"}
              >
                <ListItemText primary="Workspace Settings" />
              </ListItemButton>
              <ListItemButton
                href="/profile/workspace/manage"
                sx={{ pl: 6 }}
                selected={currentActiveTab === "WORKSPACE_MANAGE"}
              >
                <ListItemText primary="User Management" />
              </ListItemButton>
              <ListItemButton
                href="/profile/user"
                sx={{ pl: 6 }}
                selected={currentActiveTab === "PROFILE_USER"}
              >
                <ListItemText primary="Profile Settings" />
              </ListItemButton>
              <ListItemButton
                href="/profile/workspace/credit-utilization"
                sx={{ pl: 6 }}
                selected={currentActiveTab === "CREDIT_UTILIZATION"}
              >
                <ListItemText primary="Credit Consumption & Plan" />
              </ListItemButton>
            </List>
          </Collapse>
        </Stack>
        {/* Credit Upgrade */}
        <Stack gap={1.5} sx={{ justifySelf: "end" }} padding={"20px"}>
          {workspaceApiStatus === STATUS.SUCCESS ? (
            <>
              {!availableCredits ? (
                <Alert severity="error" variant="standard">
                  You’ve run out of credits. Upgrade your account to continue
                  using MurfDub.
                </Alert>
              ) : shouldShowAutoPayFailedError ? (
                <Alert severity="error">
                  Your autopay has failed. Buy more credits to continue.
                </Alert>
              ) : availableCredits <= 0.2 ? (
                <Alert severity="warning" variant="standard">
                  You’re about to run out of credits. Upgrade your account.
                </Alert>
              ) : null}
            </>
          ) : null}
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Typography variant="body2">Credits left</Typography>
            <Stack direction={"row"} alignItems={"center"} gap={0.25}>
              <CreditsIcon
                style={{
                  height: 14,
                  width: 14
                }}
              />
              {workspaceApiStatus === STATUS.SUCCESS ? (
                <Typography
                  variant="subtitle2"
                  color={
                    !availableCredits
                      ? "error.main"
                      : availableCredits <= 0.2
                      ? "warning.main"
                      : "success.main"
                  }
                >
                  {assignedCredits - usedCredits}
                  {/* {Math.max(0, assignedCredits - usedCredits)} */}
                </Typography>
              ) : (
                <Skeleton width={24} height={24} variant="text" />
              )}
            </Stack>
          </Stack>
          <Button
            variant="contained"
            color={isIcpUser ? "secondary" : "primary"}
            startIcon={<UpgradeRounded sx={{ width: 24, height: 24 }} />}
            onClick={handleUpgrade}
            size="large"
          >
            {planCategory === PLANS_CATEGORY.CUSTOM
              ? "Contact Sales"
              : planCategory === PLANS_CATEGORY.PAY_AS_YOU_GO
              ? "Top-up"
              : "Upgrade"}
          </Button>
          {planCategory !== PLANS_CATEGORY.CUSTOM ? (
            <Button
              variant="contained"
              color={isIcpUser ? "primary" : "secondary"}
              onClick={onContactSales}
              size="large"
            >
              Contact Sales
            </Button>
          ) : null}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ClientSideBar;
