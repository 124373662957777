import { getBlockError } from "@/features/synthesis/util/blockErrorHandling";
import {
  BlockError,
  LocalScriptBlockType,
  ScriptBlockType,
  TextBlockStatus
} from "..";
import {
  BLOCK_ERROR_MESSAGES,
  BLOCK_ERROR_TYPE,
  BLOCK_MAX_CHAR
} from "../constants";
import { QC_ACCESS_ROLES } from "@/types/user";
import { IIssue, ISSUE_TAG_RESOLVE_STATUS } from "@/features/comments";

export const BLOCK_LENGTH_VARIANCE_THRESHOLD = 0.4;

/**
 * function to get block length error if exists
 * @param block
 * @returns - block error
 */
export const hasBlockLengthError = (
  block: ScriptBlockType
): BlockError | null => {
  const blockLengthVariance = block.original.duration - block.renderLength;

  // if block lengths are less than 1 then ignore block error
  if (block.original.duration <= 1 && block.renderLength <= 1) return null;

  // if render length is more than original block then show exceeded error
  if (blockLengthVariance < -BLOCK_LENGTH_VARIANCE_THRESHOLD) {
    return {
      type: BLOCK_ERROR_TYPE.RENDER_LENGTH_EXCEEDED,
      message: BLOCK_ERROR_MESSAGES[BLOCK_ERROR_TYPE.RENDER_LENGTH_EXCEEDED]
    };
  } else if (blockLengthVariance > BLOCK_LENGTH_VARIANCE_THRESHOLD) {
    // if less then show insufficient error
    return {
      type: BLOCK_ERROR_TYPE.RENDER_LENGTH_INSUFFICIENT,
      message: BLOCK_ERROR_MESSAGES[BLOCK_ERROR_TYPE.RENDER_LENGTH_INSUFFICIENT]
    };
  }

  return null;
};

/**
 * function used to check present errors in block and add them to errors array
 * @param block - text block
 * @param skipTextLength - wether we want to check text length or not
 * @returns
 */
export const getBlockErrors = (
  block: ScriptBlockType,
  skipTextLength = false
) => {
  const blockErrors: BlockError[] = [];

  const renderLengthError = hasBlockLengthError(block);
  const renderError =
    block.renderStatus === TextBlockStatus.FAILED ||
    block.renderStatus === TextBlockStatus.DOES_NOT_EXIST;

  const charLimitError = skipTextLength
    ? false
    : (block.richText?.length || 0) > BLOCK_MAX_CHAR;

  if (charLimitError) {
    blockErrors.push({
      type: BLOCK_ERROR_TYPE.TEXT_LIMIT_EXCEEDED_ERROR,
      message: BLOCK_ERROR_MESSAGES[BLOCK_ERROR_TYPE.TEXT_LIMIT_EXCEEDED_ERROR]
    });
  }

  if (renderLengthError) {
    blockErrors.push(renderLengthError);
  }

  if (renderError) {
    blockErrors.push({
      type: BLOCK_ERROR_TYPE.RENDER_FAILED,
      message: getBlockError(block.renderFailureReason).errorMessage
    });
  }

  return blockErrors;
};

export const doesLocalBlockNeedsRender = (block: LocalScriptBlockType) => {
  return (
    block.affectedByBlockProperties ||
    block.affectedBySpeakerProperties ||
    block.needsRendering ||
    block.renderStatus !== TextBlockStatus.DONE
  );
};

/**
 * function used to check if block is marked as done or not based on access level
 * @param block - text block
 * @param qcEnabled
 * @param accessLevel - user access
 * @returns boolean indicating wether block is completed or not
 */
export const checkIfBlockIsCompleted = (
  block: ScriptBlockType,
  qcEnabled: boolean,
  accessLevel: QC_ACCESS_ROLES
) => {
  if (qcEnabled) {
    if (block.needsRendering || block.renderStatus !== TextBlockStatus.DONE)
      return false;
    switch (accessLevel) {
      case QC_ACCESS_ROLES.TRANSLATION:
        return block.translationMarkAsDone;
      case QC_ACCESS_ROLES.VOICEOVER:
        return block.voiceOverMarkAsDone;
      case QC_ACCESS_ROLES.TRANSLATION_VOICEOVER:
      default:
        return block.translationMarkAsDone && block.voiceOverMarkAsDone;
    }
  } else return false;
};

/**
 * function used to get the mark values after marking block
 * @param block
 * @param checked
 * @param accessLevel
 * @returns
 */
export const updateBlockCompleteValues = (
  block: ScriptBlockType,
  checked: boolean,
  accessLevel: QC_ACCESS_ROLES
) => {
  let translationMarkAsDone = block.translationMarkAsDone,
    voiceOverMarkAsDone = block.voiceOverMarkAsDone;
  const completed = checked;
  switch (accessLevel) {
    case QC_ACCESS_ROLES.TRANSLATION:
      translationMarkAsDone = checked;
      break;
    case QC_ACCESS_ROLES.VOICEOVER:
      voiceOverMarkAsDone = checked;
      break;
    case QC_ACCESS_ROLES.TRANSLATION_VOICEOVER:
      translationMarkAsDone = checked;
      voiceOverMarkAsDone = checked;
  }

  return {
    translationMarkAsDone,
    voiceOverMarkAsDone,
    completed
  };
};

export const canMarkAsDone = (
  block: LocalScriptBlockType,
  accessLevel?: QC_ACCESS_ROLES,
  issues?: IIssue[]
): { canMark: boolean; reason?: string } => {
  let canMark = true,
    reason = "";

  if (block.blockErrors.length) {
    canMark = false;
    reason = "Please resolve errors to mark as done";
  } else if (doesLocalBlockNeedsRender(block)) {
    canMark = false;
    reason = "Please render and listen the block to mark as done";
  } else if (
    accessLevel === QC_ACCESS_ROLES.VOICEOVER ||
    accessLevel === QC_ACCESS_ROLES.TRANSLATION_VOICEOVER
  ) {
    const blockLevelIssues = block.associatedIssueIds?.length
      ? issues?.filter((issue) =>
          block.associatedIssueIds?.includes(issue.issueId)
        )
      : [];

    if (!block.originalAudioPlayed) {
      canMark = false;
      reason = "Please listen the original block to mark as done";
    } else if (!block.translationAudioPlayed) {
      canMark = false;
      reason = "Please listen the translated block to mark as done";
    } else if (
      blockLevelIssues?.length &&
      blockLevelIssues.some(
        (issue: IIssue) => issue.status !== ISSUE_TAG_RESOLVE_STATUS.RESOLVED
      )
    ) {
      canMark = false;
      reason = "Please resolve issues attached to this block to mark as done";
    }
  }
  return {
    canMark,
    reason
  };
};
