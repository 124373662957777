import accessibilityUtils from "@/utils/accessibility";
import { ButtonOwnProps, styled } from "@mui/material";

interface InteractiveContainerProps {
  disabled?: boolean;
  selected?: boolean;
  elevated?: boolean;
  color?: Exclude<ButtonOwnProps["color"], "inherit" | "brand">;
  outlined?: boolean;
}

const InteractiveContainer = styled("div", {
  name: "MuiInteractiveContainer",
  slot: "root",
  shouldForwardProp: (props) =>
    props !== "selected" &&
    props !== "elevated" &&
    props !== "color" &&
    props !== "outlined" &&
    props !== "sx"
})<InteractiveContainerProps>(
  ({ theme, selected, elevated, color, outlined, disabled }) => ({
    outline: outlined
      ? `1px solid ${theme.palette[color!].outlinedBorder}`
      : "none",
    color:
      color === "secondary"
        ? theme.palette.text.primary
        : theme.palette[color!].main,
    backgroundColor: elevated ? theme.palette.background.paper : undefined,
    borderRadius: theme.shape.borderRadius * 2,
    cursor: "pointer",
    ...(disabled
      ? {
          cursor: "not-allowed",
          opacity: 0.5,
          position: "relative",
          pointerEvents: "none",
          color: theme.palette.text.disabled,
          userSelect: "none",
          "&::after": {
            position: "absolute",
            content: "''",
            inset: 0
          }
        }
      : {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
            outline: `1px solid ${theme.palette[color!].outlinedBorder}`
          },
          "&:active": {
            backgroundColor: theme.palette.action.hover,
            outline: `2px solid ${theme.palette[color!].main}`
          },
          ...(selected
            ? {
                background: theme.palette.action.selected,
                outline: `2px solid ${theme.palette[color!].main}`,
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                  outline: `2px solid ${theme.palette[color!].main}`
                }
              }
            : {})
        }),
    "&:focus-visible": accessibilityUtils.generalFocusVisibleStyle
  })
);

InteractiveContainer.defaultProps = {
  color: "secondary",
  outlined: true,
  elevated: true,
  disabled: false
};

export default InteractiveContainer;
