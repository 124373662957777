import { MurfLogo } from "@/components/elements";
import LanguagePill from "@/components/elements/LanguagePill";
import MurfVersion from "@/components/elements/MurfVersion";
import { useTypedSelector } from "@/config/configureAppStore";
import { Box, Button, Link, Paper, Typography } from "@mui/material";

const PromotionalBanner = () => {
  const fileName = useTypedSelector(
    (state) => state.dubPreview.dubDetails.fileName
  );
  const dubLanguage = useTypedSelector(
    (state) => state.dubPreview.dubDetails.language
  );
  const dubVersion = useTypedSelector(
    (state) => state.dubPreview.dubDetails.dubVersion
  );

  return (
    <Paper
      elevation={1}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 2,
        margin: 2,
        borderRadius: 4
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        <Link href="/">
          <MurfLogo width={28} height={28} />
        </Link>
        {fileName ? (
          <>
            <Typography
              color={"text.primary"}
              variant="subtitle2"
              maxWidth={500}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
            >
              {fileName}
            </Typography>
            <LanguagePill
              variant="filled"
              size="small"
              language={dubLanguage}
            />
            <MurfVersion dubVersion={dubVersion} />
          </>
        ) : null}
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Button
          variant="contained"
          color="primary"
          href={`/auth/signup${window?.location?.search || ""}`}
          target="_blank"
        >
          Signup on MurfDub
        </Button>
        <Typography variant="body2" color="text.secondary">
          OR
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          href={`/auth/login${window?.location?.search || ""}`}
          target="_blank"
        >
          Login to Murf Dub
        </Button>
      </Box>
    </Paper>
  );
};

export default PromotionalBanner;
