import React, { useMemo } from "react";
import { usePricing } from "../hooks";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { Breakpoint, Dialog } from "@mui/material";
import { PRICING_DIALOG_STEP } from "../types";
import { pricingDialogSlice } from "@/reducers/slices/pricingDialogSlice/pricingDialogSlice";
import PricingDialogContent from "./PricingDialogContent";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { trackMixpanelEvent } from "@/utils/mixpanel";
import { userThunks } from "@/reducers/thunks/user";
import usePricingDialog from "../hooks/usePricingDialog";

const PricingDialog = (): React.ReactNode => {
  const { open, activeStep, calculateCreditDialogOpen } = useTypedSelector(
    (state) => state.pricingDialog
  );
  const dispatch = useAppDispatch();
  const { cancelUpgradePreview } = usePricing();
  const { closePricingPopup } = usePricingDialog();

  const handleClose = () => {
    if (calculateCreditDialogOpen) {
      dispatch(pricingDialogSlice.actions.setCalculateCreditDialogOpen(false));
      return;
    }
    if (activeStep === PRICING_DIALOG_STEP.PAYMENT_SUCCESS) {
      return;
    }
    trackMixpanelEvent(MIXPANEL_EVENTS.UPGRADE_START_DISMISSED, {
      "Dismissal Type": "Close",
      "Page Type": activeStep
    });
    closePricingPopup();
  };

  const { minHeight, maxWidth } = useMemo(() => {
    let minHeight, maxWidth: Breakpoint | number;

    maxWidth = "md";
    if (
      calculateCreditDialogOpen ||
      activeStep === PRICING_DIALOG_STEP.PAYMENT_SUCCESS
    ) {
      minHeight = 400;
      maxWidth = "sm";
    }
    if (calculateCreditDialogOpen) {
      maxWidth = 460;
    }
    return {
      minHeight,
      maxWidth
    };
  }, [activeStep, calculateCreditDialogOpen]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={typeof maxWidth !== "number" ? maxWidth : undefined}
      PaperProps={{
        sx: {
          // height:
          // activeStep !== PRICING_DIALOG_STEP.PAYMENT_SUCCESS
          //   ? "100%"
          //   : undefined,
          minHeight: minHeight,
          minWidth: "910px",
          maxWidth: typeof maxWidth === "number" ? maxWidth : undefined
        }
      }}
      TransitionProps={{
        onExited: () => {
          cancelUpgradePreview();
          if (activeStep === PRICING_DIALOG_STEP.PAYMENT_SUCCESS) {
            dispatch(userThunks.fetchWorkspaceState());
          }
          dispatch(pricingDialogSlice.actions.resetPricingDialogState());
        }
      }}
    >
      <PricingDialogContent />
    </Dialog>
  );
};

export default PricingDialog;
