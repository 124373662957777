import accessibilityUtils from "@/utils/accessibility";
import ExpandMoreRounded from "@mui/icons-material/ExpandMoreRounded";
import {
  ButtonBase,
  Menu,
  Stack,
  Typography,
  styled,
  svgIconClasses
} from "@mui/material";
import React, { useRef, useState } from "react";

type SelectButtonProps<Value> = {
  label?: string;
  value: Value;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  highLightValue?: boolean;
  children?: React.ReactNode;
  renderValue?: (value: Value) => React.ReactNode;
  open?: boolean;
  onClose?: () => void;
};

const Wrapper = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== "selected" && prop !== "highLightValue"
})<{ selected?: boolean; highLightValue?: boolean }>(({ theme, selected }) => ({
  display: "flex",
  flexDirection: "row",
  padding: theme.spacing(0.75, 0.5, 0.75, 1),
  gap: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius * 2,

  [`& .${svgIconClasses.root}`]: {
    width: 16,
    height: 16
  },

  ...(selected
    ? {
        backgroundColor: theme.palette.secondary.selected
      }
    : {
        "&:hover": {
          backgroundColor: theme.palette.secondary.hover
        },

        "&:active": {
          backgroundColor: theme.palette.secondary.selected
        }
      }),
  [`&:focus-visible`]: {
    ...accessibilityUtils.generalFocusVisibleStyle
  }
}));

const SelectButton = <Value extends string | number>({
  label,
  value,
  onClick,
  disabled,
  highLightValue,
  children,
  renderValue,
  open,
  onClose
}: SelectButtonProps<Value>) => {
  const anchorEl = useRef(null);
  const [active, setActive] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActive(true);
    onClick?.(event);
  };

  const handleClose = () => {
    setActive(false);
    onClose?.();
  };

  const isMenuOpen = typeof open !== "undefined" ? open : active;

  return (
    <>
      <Wrapper
        selected={isMenuOpen}
        onClick={handleClick}
        disabled={disabled}
        highLightValue={highLightValue}
        ref={anchorEl}
      >
        {label ? (
          <Typography variant="body2" color="text.secondary">
            {label}
          </Typography>
        ) : null}

        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          justifyContent="space-between"
          height={1}
          sx={{
            ["& button:focus-visible"]: {
              ...accessibilityUtils.generalFocusVisibleStyle
            }
          }}
        >
          <Typography variant="subtitle2" color="secondary.main">
            {renderValue ? renderValue(value) : value}
          </Typography>
          <ExpandMoreRounded />
        </Stack>
      </Wrapper>
      <Menu
        PaperProps={{
          style: {
            maxHeight: 400
          }
        }}
        MenuListProps={{
          dense: true
        }}
        open={isMenuOpen}
        anchorEl={anchorEl.current}
        onClose={handleClose}
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement<React.HTMLAttributes<HTMLElement>>(
              child as React.ReactElement<React.HTMLAttributes<HTMLElement>>,
              {
                onClick: () => {
                  handleClose();
                  child.props.onClick?.();
                }
              }
            );
          }
          return child;
        })}
      </Menu>
    </>
  );
};

export default SelectButton;
