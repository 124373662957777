const commonVariables = {
  shadowColorLight: "rgba(7, 10, 15, 0.15)",
  shadowColorDark: "rgba(7, 10, 15, 0.75)",
  tooltipBackground: "#464C5C",
  primaryGradient: "linear-gradient(90deg, #FC6337 0%, #C516E1 100%)",
  primaryGradientHoverShadow: `0px 2px 4px 0px #EE217A80;`
};

const themeVariables = {
  light: {
    backgroundDefault: "#EDF1F7",
    backgroundPaper: "#FFFFFF",
    shadows: [
      "none",
      `0px 4px 4px -4px ${commonVariables.shadowColorLight}, 0px 1px 8px 0px ${commonVariables.shadowColorLight}`,
      `0px 16px 16px -12px ${commonVariables.shadowColorLight}, 0px 2px 16px 0px ${commonVariables.shadowColorLight}`,
      `0px 32px 32px -16px ${commonVariables.shadowColorLight}, 0px 4px 32px 0px ${commonVariables.shadowColorLight}`
    ],
    elevation: ["#FFFFFF", "#FAFBFFCC", "#FFFFFFD9", "#FFFFFFD9"],
    backdropFilter: ["none", "blur(64px)", "blur(64px)", "blur(72px)"],
    backdropBackground: "#2126327f",
    dividerColor: "#1213141A",
    elevationOutline: "rgba(25, 29, 37, 0.19)"
  },
  dark: {
    backgroundDefault: "#0F0F1A",
    backgroundPaper: "#1B1929",
    shadows: [
      "none",
      `0px 4px 4px -4px ${commonVariables.shadowColorDark}, 0px 1px 8px 0px ${commonVariables.shadowColorDark}`,
      `0px 16px 16px -12px ${commonVariables.shadowColorDark}, 0px 2px 16px 0px ${commonVariables.shadowColorDark}`,
      `0px 32px 32px -16px ${commonVariables.shadowColorDark}, 0px 4px 32px 0px ${commonVariables.shadowColorDark}`
    ],
    elevation: ["#1B1929", "#4C4A5C33", "#4C4A5C33", "#4C4A5C3D"],
    backdropFilter: ["none", "blur(72px)", "blur(72px)", "blur(72px)"],
    backdropBackground: "#000000CC",
    dividerColor: "#EDF2FF1A",
    elevationOutline: "#9A93CC1A"
  }
};

const MISC_COLORS = {
  FOCUS_COLOR: "#64b5f6"
};

export { themeVariables, commonVariables, MISC_COLORS };
