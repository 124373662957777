import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "@/constants/routeUrls";
import { FIREBASE } from "murflib";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { setAuthError, setAuthStatus } from "@/reducers/slices/authSlice";
import { STATUS } from "@/constants/status";
import { FirebaseInstance } from "@/lib/firebase";
import { authApi } from "@/features/authentication/api";
import { SERVER_RESPONSES } from "@/constants/serverConstants";
import { AuthUser } from "@/types/firebase";
import { userThunks } from "@/reducers/thunks/user";
import {
  OrganizationData,
  UserStateResponse,
  userStateApi
} from "@/features/userState/api";
import { canRoleAccessRoute } from "@/features/permissions/util/routePolicies";
import { USER_INVITE_PENDING, storageService } from "@/utils/storage";
import { MURF_ROLES } from "@/features/user";
import { trackMixpanelEvent } from "@/utils/mixpanel";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import {
  GA4_EMAIL_TYPES,
  GA4_SIGNUP_SUCCESS_FRONTEND,
  GA4_SIGNUP_SUCCESS_BACKEND,
  GA4_LOGIN_SUCCESS_FRONTEND,
  GA4_LOGIN_SUCCESS_BACKEND
} from "@/constants/gaEvents";
import { getGa4Data, trackGA4Event, trackGTMEvent } from "@/utils/analytics";
import { getEmailDomain, getQueryParams, isCorporate } from "@/utils/auth";
import { captureMessage } from "@sentry/react";
import { UNKNOWN_ERROR_MESSAGE } from "@/constants/errors";
import { setOrganizationDetails } from "@/reducers/slices/globalSlice";

export const notifyGaSignupSuccess = async (
  authUser: AuthUser,
  promptType: PROMPT_TYPES
) => {
  const { EVENT_PARAMS } = GA4_SIGNUP_SUCCESS_FRONTEND;
  const emailType = isCorporate(authUser.email)
    ? GA4_EMAIL_TYPES.BUSINESS
    : GA4_EMAIL_TYPES.PERSONAL;

  let organizationData: OrganizationData | null = null;
  try {
    const response = await userStateApi.getOrganizationDetails();
    organizationData = response.data.responseData;
    trackGTMEvent("dubbing_signup_success", {
      category: "user",
      action: "signup",
      label: "user_signup",
      value: 1,
      employeeSize: organizationData?.employeeSize ?? "unknown",
      companyName: organizationData?.companyName ?? "unknown",
      emailType: organizationData?.emailType ?? "unknown",
      country: organizationData?.country ?? "unknown"
    });
  } catch (error) {
    // console.log(error);
  } finally {
    let params = {};
    const queries = getQueryParams();
    if (organizationData) {
      params = {
        ...params,
        [EVENT_PARAMS.COMPANY_SIZE]: organizationData?.employeeSize,
        [EVENT_PARAMS.COMPANY_NAME]: organizationData?.companyName,
        [EVENT_PARAMS.COUNTRY]: organizationData?.country
      };
    }
    trackGA4Event(GA4_SIGNUP_SUCCESS_FRONTEND.EVENT_NAME, {
      ...params,
      ...queries,
      [EVENT_PARAMS.EMAIL_DOMAIN]: getEmailDomain(authUser.email),
      [EVENT_PARAMS.EMAIL_TYPE]: emailType
        ? GA4_EMAIL_TYPES.BUSINESS
        : GA4_EMAIL_TYPES.PERSONAL,
      [EVENT_PARAMS.SIGNUP_METHOD]: promptType,
      [EVENT_PARAMS.TIMESTAMP]: new Date()
    });
  }

  getGa4Data()
    .then((gaData: any) => {
      if (!gaData) throw new Error("GA4 data not found");

      const queries = getQueryParams();
      const { sessionId, clientId } = gaData;
      return userStateApi.sendGaEvent({
        eventName: GA4_SIGNUP_SUCCESS_BACKEND.EVENT_NAME,
        emailId: authUser.email,
        userId: authUser.uid,
        additionalProperties: {
          emailType,
          signupMethod: promptType,
          ...queries
        },
        clientId,
        sessionId,
        enrichData: true
      });
    })
    .catch((e) => {
      captureMessage("GA signup success event failed", {
        level: "error",
        extra: {
          error: e,
          reason: e?.extra || e?.message || UNKNOWN_ERROR_MESSAGE
        }
      });
    });
};

const notifyGaLoginSuccess = async (
  authUser: AuthUser,
  promptType: PROMPT_TYPES
) => {
  const { EVENT_PARAMS } = GA4_LOGIN_SUCCESS_FRONTEND;

  let organizationData: OrganizationData | null = null;
  try {
    const response = await userStateApi.getOrganizationDetails();
    organizationData = response.data.responseData;
    trackGTMEvent("dubbing_login_success", {
      category: "user",
      action: "signup",
      label: "user_signup",
      value: 1,
      employeeSize: organizationData?.employeeSize ?? "unknown",
      companyName: organizationData?.companyName ?? "unknown",
      emailType: organizationData?.emailType ?? "unknown",
      country: organizationData?.country ?? "unknown"
    });
  } catch (error) {
    console.log(error);
  } finally {
    let params = {};
    const queries = getQueryParams();
    if (organizationData) {
      params = {
        ...params,
        [EVENT_PARAMS.COMPANY_SIZE]: organizationData?.employeeSize,
        [EVENT_PARAMS.COMPANY_NAME]: organizationData?.companyName,
        [EVENT_PARAMS.COUNTRY]: organizationData?.country
      };
    }
    trackGA4Event(GA4_LOGIN_SUCCESS_FRONTEND.EVENT_NAME, {
      ...params,
      ...queries,
      [GA4_LOGIN_SUCCESS_FRONTEND.EVENT_PARAMS.EMAIL_DOMAIN]: getEmailDomain(
        authUser.email
      ),
      [GA4_LOGIN_SUCCESS_FRONTEND.EVENT_PARAMS.EMAIL_TYPE]: isCorporate(
        authUser.email
      )
        ? GA4_EMAIL_TYPES.BUSINESS
        : GA4_EMAIL_TYPES.PERSONAL,
      [GA4_LOGIN_SUCCESS_FRONTEND.EVENT_PARAMS.SIGNUP_METHOD]: promptType,
      [GA4_LOGIN_SUCCESS_FRONTEND.EVENT_PARAMS.TIMESTAMP]: new Date()
    });

    const emailType = isCorporate(authUser.email)
      ? GA4_EMAIL_TYPES.BUSINESS
      : GA4_EMAIL_TYPES.PERSONAL;

    getGa4Data()
      .then((gaData: any) => {
        if (!gaData) throw new Error("GA4 data not found");
        const { sessionId, clientId } = gaData;
        const queries = getQueryParams();

        return userStateApi.sendGaEvent({
          eventName: GA4_LOGIN_SUCCESS_BACKEND.EVENT_NAME,
          emailId: authUser.email,
          userId: authUser.uid,
          clientId,
          sessionId,
          enrichData: true,
          additionalProperties: {
            emailType,
            signupMethod: promptType,
            ...queries
          }
        });
      })
      .catch((error) => {
        captureMessage("GA login success event failed", {
          level: "error",
          extra: {
            error,
            reason: error?.extra || error?.message || UNKNOWN_ERROR_MESSAGE
          }
        });
      });
  }
};

export enum PROMPT_TYPES {
  EMAIL_LOGIN = "EMAIL_LOGIN",
  EMAIL_SIGNUP = "EMAIL_SIGNUP",
  FB_LOGIN = "FB_LOGIN",
  FB_SIGNUP = "FB_SIGNUP",
  GOGL_LOGIN = "GOGL_LOGIN",
  GOGL_SIGNUP = "GOGL_SIGNUP",
  LNKDN_LOGIN = "LNKDN_LOGIN",
  LNKDN_SIGNUP = "LNKDN_SIGNUP",
  SSO_LOGIN = "SSO_LOGIN",
  SSO_SIGNUP = "SSO_SIGNUP",
  MICROSOFT_LOGIN = "MICROSOFT_LOGIN",
  SLACK_LOGIN = "SLACK_LOGIN"
}

function useAuthMethods() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const status = useTypedSelector((state) => state.auth.status);
  const error = useTypedSelector((state) => state.auth.error);
  const [, setSearchParams] = useSearchParams();

  const handleRedirect = useCallback(
    ({
      user,
      userState: { murfRole, linkedWorkspaces },
      pathToPush
    }: {
      user: AuthUser;
      userState: UserStateResponse;
      pathToPush?: string;
    }) => {
      if (pathToPush) {
        navigate(pathToPush);
      }

      const pathName = window.location.pathname;

      if (pathName.includes("/auth")) {
        return;
      }
      if (!user?.emailVerified) {
        // If user is not verified
        window.fromRoute = pathName;
        if (
          pathName.includes("url-reply/accept-linking") ||
          pathName.includes("url-reply/accept-member-invite")
        ) {
          storageService.setItem(
            USER_INVITE_PENDING,
            `${pathName}${window.location.search}`
          );
        }
        navigate("/auth/verify-email", {
          replace: true
        });
        return;
      }
      const isInvitePending = storageService.getItem(USER_INVITE_PENDING);
      if (isInvitePending) {
        navigate(isInvitePending);
        return;
      }

      const shouldShowPricingDialog =
        storageService.getItem("showPricingDialog");
      const planId = storageService.getItem("showPricingDialogForPlanId");
      if (shouldShowPricingDialog) {
        const searchParamObj: Record<string, string> = {
          intent: "pricing"
        };
        if (planId) {
          searchParamObj["planCategory"] = planId;
        }
        setSearchParams(searchParamObj);
        return;
      }

      if (
        pathName.includes("url-reply/accept-linking") ||
        pathName.includes("url-reply/accept-member-invite")
      ) {
        return;
      }
      if (
        (murfRole === MURF_ROLES.USER || !murfRole) &&
        linkedWorkspaces.length <= 0
      ) {
        // If user doesn't have any workspace
        navigate("/auth/book-demo", {
          replace: true,
          state: { from: pathName }
        });
        return;
      }
      // if location is default redirect to pages based on roles
      if (pathName === "/") {
        if (murfRole == MURF_ROLES.USER || !murfRole) {
          return;
        } else {
          navigate(
            `/internal${
              murfRole === MURF_ROLES.CONTRIBUTOR ? "/my-tasks" : ""
            }`,
            { replace: true }
          );
          return;
        }
      } else {
        if (!canRoleAccessRoute(pathName, murfRole)) {
          if (murfRole === MURF_ROLES.USER) {
            navigate("/", { replace: true });
            return;
          } else {
            navigate(
              `/internal${
                murfRole === MURF_ROLES.CONTRIBUTOR ? "/my-tasks" : ""
              }`,
              { replace: true }
            );
            return;
          }
        }
      }
    },
    [navigate, setSearchParams]
  );

  const updateUserDataAndRedirect = useCallback(
    async ({
      firebaseResult,
      redirectRequired = false,
      path,
      promptType,
      sendEvent = true
    }: {
      firebaseResult: {
        user: AuthUser | null | undefined;
        additionalUserInfo?: any;
      };
      redirectRequired?: boolean;
      sendEvent?: boolean;
      path?: string;
      promptType?: PROMPT_TYPES;
    }) => {
      const user = firebaseResult?.user;

      const basePath = `${window.location.pathname}${window.location.search}`;
      // not
      if (!user) {
        if (
          basePath.includes("url-reply/accept-linking") ||
          basePath.includes("url-reply/accept-member-invite")
        ) {
          storageService.setItem(USER_INVITE_PENDING, basePath);
        }
        if (
          !window.location.pathname.includes("/auth") &&
          !basePath.includes("unauthenticated")
        ) {
          window.fromRoute = path ?? basePath;
          navigate(
            {
              pathname: "/auth/signup",
              search: window.location.search
            },
            {
              replace: true
            }
          );
        }
        return;
      }

      const isNewUser =
        FirebaseInstance?.getAdditionalUserInfo(firebaseResult)?.isNewUser;

      try {
        dispatch(userThunks.fetchVisitorRegion());
        const res = await dispatch(userThunks.fetchUserState()).unwrap();
        dispatch(userThunks.fetchWorkspaceState());

        // fetch organization details and save it in redux;

        userStateApi
          .getOrganizationDetails()
          .then((res) => {
            if (res.data.responseData) {
              dispatch(setOrganizationDetails(res.data.responseData));
            }
          })
          .catch((e) => {
            console.debug("Unable to fetch Organisation details", e);
          });

        if (sendEvent && isNewUser) {
          notifyGaSignupSuccess(user, promptType!);
        }

        if (redirectRequired) {
          handleRedirect({
            user,
            userState: res,
            pathToPush: window.fromRoute ?? path
          });
          window.fromRoute = undefined;
        }
      } catch (error) {
        //TODO:show error screen
      }
    },
    [dispatch, handleRedirect, navigate]
  );

  const googleLogin = useCallback(
    (_type = PROMPT_TYPES.GOGL_LOGIN) => {
      console.log(_type);
      const isInvited = storageService.getItem(USER_INVITE_PENDING);
      trackMixpanelEvent(MIXPANEL_EVENTS.LOGIN_START, {
        "Sign In Method": "Google",
        Source: null,
        Invited: isInvited
      });
      dispatch(setAuthStatus(STATUS.LOADING));
      FirebaseInstance?.doSignInWithGoogle()
        .then((_result: any) => {
          dispatch(setAuthStatus(STATUS.SUCCESS));
          trackMixpanelEvent(MIXPANEL_EVENTS.LOGIN_SUCCESS, {
            "Sign In Method": "Google",
            Source: null,
            Invited: isInvited
          });
          const isNewUser =
            FirebaseInstance?.getAdditionalUserInfo(_result)?.isNewUser;
          if (!isNewUser) {
            notifyGaLoginSuccess(_result.user, PROMPT_TYPES.GOGL_LOGIN);
          }
          updateUserDataAndRedirect({
            firebaseResult: _result,
            redirectRequired: true,
            path: "/",
            promptType: PROMPT_TYPES.GOGL_LOGIN
          });
        })
        .catch((error: any) => {
          dispatch(setAuthStatus(STATUS.ERROR));
          dispatch(setAuthError(FIREBASE.errorToString(error)));
        });
    },
    [dispatch, updateUserDataAndRedirect]
  );

  const passwordLogin = useCallback(
    (email: string, password: string) => {
      trackMixpanelEvent(MIXPANEL_EVENTS.LOGIN_START, {
        "Sign In Method": "Email",
        Source: null
      });
      return FirebaseInstance?.doSignInWithEmailAndPassword(email, password)
        .then((_result: any) => {
          trackMixpanelEvent(MIXPANEL_EVENTS.LOGIN_SUCCESS, {
            "Sign In Method": "Email",
            Source: null
          });
          notifyGaLoginSuccess(_result.user, PROMPT_TYPES.EMAIL_LOGIN);
          updateUserDataAndRedirect({
            firebaseResult: _result,
            redirectRequired: true,
            path: "/",
            promptType: PROMPT_TYPES.EMAIL_LOGIN
          });
        })
        .catch((error: any) => {
          throw new Error(FIREBASE.errorToString(error));
        });
    },
    [updateUserDataAndRedirect]
  );

  const logout = useCallback(
    (redirect: boolean = true) => {
      FirebaseInstance?.doSignOut().then(() => {
        if (redirect) {
          navigate(ROUTES.LOGIN);
        }
      });
    },
    [navigate]
  );

  const createAccount = (
    email: string,
    password: string,
    firstName?: string,
    lastName?: string
  ) => {
    const isInvited = storageService.getItem(USER_INVITE_PENDING);

    trackMixpanelEvent(MIXPANEL_EVENTS.SIGNUP_START, {
      "Sign Up Method": "Email",
      Invited: isInvited,
      Source: null,
      "One tap": false
    });

    return FirebaseInstance?.doCreateUserWithEmailAndPassword(email, password)
      .then((res: any) => {
        if (firstName) {
          FirebaseInstance?.doUpdateDisplayName(
            `${firstName} ${lastName}`
          ).catch((e: any) => {
            console.log(e);
          });

          // TODO handle error
          userStateApi.updateUserName({
            firstName: firstName,
            lastName: lastName ?? "",
            displayName: `${firstName} ${lastName ?? ""}`
          });
        }
        trackMixpanelEvent(MIXPANEL_EVENTS.SIGNUP_SUCCESS, {
          "Sign Up Method": "Email",
          Invited: isInvited,
          Source: null,
          "One tap": false
        });

        notifyGaSignupSuccess(res?.user, PROMPT_TYPES.EMAIL_SIGNUP);
        authApi.sendVerification();
        navigate("/auth/verify-email", { replace: true });
      })
      .catch((error: any) => {
        if (error.code === FIREBASE?.AuthErrorCodes?.EMAIL_EXISTS) {
          return passwordLogin(email, password);
        }
        throw new Error(FIREBASE.errorToString(error));
      });
  };

  const resetPassword = (email: string) => {
    return FirebaseInstance?.doPasswordReset(
      email,
      `${import.meta.env.VITE_MURF_DOMAIN}/auth/login`
    ).catch((error: any) => {
      throw new Error(FIREBASE.errorToString(error));
    });
  };

  const signInWithSSO = (email: string) =>
    new Promise((resolve, reject) => {
      authApi
        .signInWithSSO(email)
        .then((res) => {
          if (res.data.responseCode === SERVER_RESPONSES.SUCCESS) {
            if (res.data.responseData.authType === "OIDC") {
              FirebaseInstance?.doOidcLogin(res.data.responseData.extra)
                .then((res: any) => {
                  updateUserDataAndRedirect({
                    firebaseResult: res,
                    redirectRequired: true,
                    path: "/",
                    promptType: PROMPT_TYPES.SSO_LOGIN
                  });
                  resolve(true);
                })
                .catch((error: any) => {
                  reject(FIREBASE.errorToString(error));
                });
            } else if (res.data.responseData.authType === "SAML") {
              FirebaseInstance?.doSamlLogin(res.data.responseData.extra)
                .then((res: any) => {
                  updateUserDataAndRedirect({
                    firebaseResult: res,
                    redirectRequired: true,
                    path: "/",
                    promptType: PROMPT_TYPES.SSO_LOGIN
                  });
                  resolve(true);
                })
                .catch((error: any) => {
                  reject(FIREBASE.errorToString(error));
                });
            }
          } else {
            reject(res.data.extra || res.data.responseMessage);
          }
        })
        .catch((error) => {
          console.log(error);
          if (typeof error === "string") {
            reject(error);
          } else {
            reject(error?.responseMessage || error?.extra);
          }
        });
    });

  return {
    googleLogin,
    logout,
    passwordLogin,
    createAccount,
    status,
    error,
    resetPassword,
    signInWithSSO,
    updateUserDataAndRedirect
  };
}

export default useAuthMethods;
