import { MISC_COLORS } from "@/themes/themeVariables";
import { KEY_BOARD_KEYS } from "./misc";

const handleOnKeyDown = (callBack: any) => (event: React.KeyboardEvent) => {
  event.stopPropagation();
  if (
    event.key === KEY_BOARD_KEYS.Enter ||
    event.key === KEY_BOARD_KEYS.Space
  ) {
    callBack();
  }
};

const generalFocusVisibleStyle = {
  outline: `2px solid`,
  outlineOffset: 2,
  outlineColor: MISC_COLORS.FOCUS_COLOR
};

const accessibilityUtils = {
  handleOnKeyDown,
  generalFocusVisibleStyle
};

export default accessibilityUtils;
