import { useState, useRef, useMemo } from "react";
import { IconButton, Popover, Slider, Box } from "@mui/material";
import {
  VolumeUpRounded,
  VolumeDownRounded,
  VolumeMuteRounded,
  VolumeOff
} from "@mui/icons-material";
import { useMediaPlayer, setVolume } from "@/features/mediaPlayer";

export function PlayerVolumeControls({
  audioPlayerId
}: {
  audioPlayerId: string;
}) {
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useMediaPlayer(audioPlayerId)!;
  const lastVolume = useRef(state?.volume ?? 1);
  const anchorEl = useRef(null);

  const handleClick = () => {
    setOpen((open) => !open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleVolumeChange = (_e: any, value: any) => {
    setVolume(dispatch, value / 100);
  };

  const id = open ? "simple-popover" : undefined;

  const playerVolume = useMemo(() => {
    return state?.volume ?? 1;
  }, [state.volume]);

  const handleMute = () => {
    if (playerVolume <= 0) handleVolumeChange("", lastVolume.current * 100);
    else {
      lastVolume.current = playerVolume;
      handleVolumeChange("", 0);
    }
  };

  return (
    <>
      <IconButton
        ref={anchorEl}
        id="volume-button"
        onClick={handleClick}
        aria-label="volume controls"
        sx={{
          p: 0.5
        }}
      >
        {playerVolume > 0.5 ? (
          <VolumeUpRounded
            id="volume-up-button"
            fontSize="small"
            sx={{
              color: "common.white"
            }}
          />
        ) : playerVolume > 0 ? (
          <VolumeDownRounded
            id="volume-down-button"
            fontSize="small"
            sx={{
              color: "common.white"
            }}
          />
        ) : (
          <VolumeMuteRounded
            id="volume-mute-button"
            fontSize="small"
            sx={{
              color: "common.white"
            }}
          />
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl.current}
        disablePortal
        // container={container}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "230px",
              width: "30px"
            }
          }
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ overflow: "hidden", height: "100%", gap: 1, paddingTop: 2 }}
        >
          <Slider
            sx={{
              height: "200px"
            }}
            color="secondary"
            value={playerVolume * 100}
            onChange={handleVolumeChange}
            orientation="vertical"
            aria-label="volume"
          />
          <IconButton onClick={handleMute} size="small">
            {playerVolume <= 0 ? (
              <VolumeOff
                fontSize="small"
                sx={{
                  color: "common.white"
                }}
              />
            ) : (
              <VolumeUpRounded
                fontSize="small"
                sx={{
                  color: "common.white"
                }}
              />
            )}
          </IconButton>
        </Box>
      </Popover>
    </>
  );
}
