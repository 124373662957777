import React, { useRef, useMemo } from "react";
import {
  seekTo,
  setSeeking,
  setProgress,
  useMediaPlayer,
  requestFullScreen,
  toggleSubtitles
} from "@/features/mediaPlayer";
import { getSeekerTime } from "@/utils/time";
import {
  ClosedCaptionOff,
  ClosedCaptionRounded,
  FullscreenRounded
} from "@mui/icons-material";
import { IconButton, Slider, Stack, Typography, styled } from "@mui/material";
import { PlayButton } from "../..";
import { PlayerVolumeControls } from "./PlayerVolumeControls";
import { pluralize } from "@/utils/misc";

const StyledSlider = styled(Slider)(({ theme }) => ({
  marginBottom: 0,
  "& .MuiSlider-mark": {
    height: "5px",
    width: "5px",
    borderRadius: "50%",
    opacity: 1
  },
  "& .MuiSlider-rail": {
    backgroundColor: theme.palette.common.white,
    height: 8
  },
  "& .MuiSlider-markLabel": {
    top: "auto",
    bottom: "15px"
  },
  "& .MuiSlider-track": {
    backgroundColor: "error.light",
    height: 8,
    border: 0
  },
  "& .MuiSlider-thumb": {
    height: 12,
    width: 12
  }
}));

interface PlayerControlsProps {
  showFullScreen: boolean;
  audioPlayerId: string;
  seekerMarks?: {
    value: number;
    label: JSX.Element;
  }[];
  enableCustomFullScreenControls?: boolean;
  subtitlesAvailable?: boolean;
}

const PlayerControls = ({
  showFullScreen = true,
  audioPlayerId,
  seekerMarks = [],
  enableCustomFullScreenControls,
  subtitlesAvailable
}: PlayerControlsProps): React.ReactNode => {
  const isFullScreen = useRef(false);

  const {
    state,
    dispatch: mediaPlayerDispatch,
    mediaPlayerRef
  } = useMediaPlayer(audioPlayerId)!;

  const duration = mediaPlayerRef.current?.getDuration() || 0;

  const handleFullScreenClick = () => {
    if (enableCustomFullScreenControls) {
      isFullScreen.current = !isFullScreen.current;
      const wrapper = document.getElementById(`wrapper-${audioPlayerId}`);
      if (wrapper) {
        if (isFullScreen.current) {
          wrapper.requestFullscreen?.();
        } else {
          document.exitFullscreen();
        }
      }
    } else {
      requestFullScreen(mediaPlayerDispatch);
    }
  };
  const durationMinWidth = useMemo(() => {
    const formattedValue = getSeekerTime({
      timeValue: duration,
      isCyclic: Boolean(duration > 3600)
    });
    if (formattedValue.length > 5) {
      return 60;
    }
    return 40;
  }, [duration]);

  const seekerTime = useMemo(() => {
    return getSeekerTime({
      timeValue: Number(mediaPlayerRef.current?.getCurrentTime() || 0),
      isCyclic: Boolean(duration > 3600)
    });
  }, [mediaPlayerRef, duration]);

  const seekerText = useMemo(() => {
    let [hours, minutes, seconds] = [0, 0, 0];
    if (seekerTime.length > 5) {
      [hours, minutes, seconds] = seekerTime.split(":").map(Number);
    } else {
      [minutes, seconds] = seekerTime.split(":").map(Number);
    }

    const hoursText = pluralize(hours, "hour");
    const minutesText = pluralize(minutes, "minute");
    const secondsText = pluralize(seconds, "second");

    const readableText = [hours ? hoursText : 0, minutesText, secondsText]
      .filter(Boolean)
      .join(" and ");
    return readableText;
  }, [seekerTime]);

  return (
    <Stack
      px={1}
      py={0}
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={2}
      bgcolor={"common.black"}
      sx={{
        [`& button:focus-visible`]: {
          outlineOffset: 0
        }
      }}
    >
      <PlayButton
        audioPlayerId={audioPlayerId}
        iconProps={{
          sx: { height: 24, width: 24, color: "common.white" }
        }}
      />
      <Typography
        variant="body2"
        sx={{ color: "common.white", minWidth: durationMinWidth }}
      >
        {seekerTime}
      </Typography>
      <StyledSlider
        id={`${audioPlayerId}-seeker`}
        aria-label="media progress slider"
        value={state.mediaProgressFraction * 100}
        max={100}
        marks={seekerMarks}
        color="error"
        aria-valuetext={seekerText}
        onChange={(_, newValue: number | number[]) => {
          if (Array.isArray(newValue)) {
            return;
          }
          setSeeking(mediaPlayerDispatch, true);
          setProgress(
            mediaPlayerDispatch,
            (newValue / 100) * mediaPlayerRef.current!.getDuration()
          );
        }}
        onChangeCommitted={(_, newValue: number | number[]) => {
          if (Array.isArray(newValue)) {
            return;
          }
          setSeeking(mediaPlayerDispatch, false);
          seekTo(mediaPlayerDispatch, {
            amount: newValue / 100,
            type: "fraction"
          });
        }}
      />
      <Typography
        variant="body2"
        sx={{ color: "common.white", minWidth: durationMinWidth }}
      >
        {getSeekerTime({
          timeValue: duration,
          isCyclic: Boolean(duration > 3600)
        })}
      </Typography>
      <PlayerVolumeControls audioPlayerId={audioPlayerId} />
      {subtitlesAvailable ? (
        <IconButton
          sx={{ p: 1, color: "common.white" }}
          onClick={() => {
            toggleSubtitles(mediaPlayerDispatch);
          }}
        >
          {state.showSubtitles ? (
            <ClosedCaptionRounded
              sx={{
                height: 24,
                width: 24
              }}
            />
          ) : (
            <ClosedCaptionOff sx={{ height: 24, width: 24 }} />
          )}
        </IconButton>
      ) : null}
      {showFullScreen ? (
        <IconButton
          onClick={handleFullScreenClick}
          sx={{ p: 0.5, color: "common.white" }}
          aria-label="full-screen"
        >
          <FullscreenRounded
            sx={{
              height: 24,
              width: 24
            }}
          />
        </IconButton>
      ) : null}
    </Stack>
  );
};

export default PlayerControls;
