import { MurfLogo } from "@/components/elements";
import GlassmorphismContainer from "@/features/authentication/components/GlassmorphismContainer";
import { commonVariables } from "@/themes/themeVariables";
import { getIconUrl, murfLogoWithNameUrl } from "@/utils/links";
import {
  CircularProgress,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  styled
} from "@mui/material";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

const Wrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr auto",
  height: "100vh",
  overflow: "hidden",

  "& .hero-section": {
    padding: theme.spacing(2, 2, 8, 8),
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(3),
    height: "100%",
    overflow: "auto"
  },
  [`& .list-disc`]: {
    fontSize: 12,
    minWidth: 12,
    margin: theme.spacing(1, 1.5, 1, 1),

    "& .ellipse-icon": {
      width: 10,
      height: 10,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main
    }
  },

  ["& .right-section"]: {
    padding: theme.spacing(3, 8),
    height: "100%",
    overflow: "auto",

    ["@media (max-height: 580px) or (max-width: 1280px)"]: {
      padding: 0,
      ["& .login-form"]: {
        borderRadius: 0,
        overflow: "auto",
        height: "100%",
        maxWidth: "none",
        maxHeight: "none"
      }
    },
    [theme.breakpoints.up("xl")]: {
      display: "flex",
      alignItems: "center"
    }
  }
}));

const AuthPattern1 = () => {
  return (
    <Wrapper>
      <div className="hero-section">
        <Link
          href={`/auth/signup${window?.location?.search || ""}`}
          display="block"
          width="max-content"
          padding={(theme) => theme.spacing(1.5)}
          borderRadius={2}
          bgcolor="#2B3140"
        >
          <MurfLogo src={murfLogoWithNameUrl} height={28} padding={0} />
        </Link>
        <Typography variant="h3" fontWeight={700} maxWidth="22ch">
          Accelerate Localisation with{" "}
          <Typography
            sx={{
              backgroundImage: commonVariables.primaryGradient,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent"
            }}
            variant="inherit"
            component="span"
            className="title"
          >
            AI Dubbing
          </Typography>
        </Typography>
        <List disablePadding sx={{ mb: 3 }}>
          <ListItem disablePadding>
            <ListItemIcon className="list-disc">
              <div className="ellipse-icon"></div>
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ color: "text.secondary" }}
              primary="High-quality dubbing in 10+ languages"
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon className="list-disc">
              <div className="ellipse-icon"></div>
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ color: "text.secondary" }}
              primary="10X faster dubbing than traditional methods"
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon className="list-disc">
              <div className="ellipse-icon"></div>
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ color: "text.secondary" }}
              primary="Boost content engagement rates by 8X"
            />
          </ListItem>
        </List>
        <Divider />
        <MurfLogo
          src={getIconUrl({ iconNameWithExtension: "gdpr-iso.svg" })}
          height={60}
          padding={0}
          marginTop={1}
        />
      </div>
      <div className="right-section">
        <GlassmorphismContainer className="login-form">
          <Suspense
            fallback={
              <Stack justifyContent="center" flexDirection="row">
                <CircularProgress />
              </Stack>
            }
          >
            <Outlet />
          </Suspense>
        </GlassmorphismContainer>
      </div>
    </Wrapper>
  );
};

export default AuthPattern1;
