import { Box, BoxProps } from "@mui/material";
import { murfLogoUrl } from "@/utils/links";

interface MurfLogoImageProps {
  src?: string;
  height?: number;
  width?: number;
  maxHeight?: number;
  maxWidth?: number;
  padding?: number;
}

type MurfLogoProps = MurfLogoImageProps & BoxProps;

export function MurfLogo({
  height,
  width,
  src,
  padding = 1,
  ...props
}: MurfLogoProps) {
  return (
    <Box display={"flex"} {...props} p={padding}>
      <img
        src={src ?? murfLogoUrl}
        height={height ?? undefined}
        width={width ?? undefined}
        alt="Murf.AI"
        aria-label="Murf.AI"
      />
    </Box>
  );
}
