import React, { useState } from "react";
import { DubPlayerCommentButton } from "./DubPlayerCommentButton";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import accessibilityUtils from "@/utils/accessibility";
import { useSearchParams } from "react-router-dom";

interface DubPlayerCommentGroupProps {
  groupedIssues: string[];
  dubId: string;
}

export const DubPlayerCommentGroup = ({
  groupedIssues,
  dubId
}: DubPlayerCommentGroupProps): React.ReactNode => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const [, setSearchParams] = useSearchParams();

  const handleIssueClick = (issueId: string) => {
    setSearchParams(
      (prev) => {
        prev.set("issue", issueId);
        return prev;
      },
      {
        replace: true
      }
    );
  };

  if (!groupedIssues?.length) {
    return null;
  }
  if (groupedIssues.length === 1) {
    return <DubPlayerCommentButton dubId={dubId} issueId={groupedIssues[0]} />;
  }

  return (
    <Box>
      <IconButton
        sx={{
          height: 32,
          width: 32,
          zIndex: 2,
          p: "2px"
        }}
        onClick={handleClick}
        aria-describedby={"dub-player-comment-group"}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            height: "100%",
            width: "100%",
            backgroundColor: "common.white"
          }}
        >
          {open ? (
            <CloseRounded
              sx={{
                height: 16,
                width: 16,
                color: "common.black"
              }}
            />
          ) : (
            <Typography variant="subtitle1" color={"common.black"}>
              {groupedIssues.length}
            </Typography>
          )}
        </Box>
      </IconButton>
      <Menu
        id={"dub-player-comment-group"}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        MenuListProps={{
          "aria-label": "Dub Player Comment Group",
          role: "listbox",
          disableListWrap: true,
          disablePadding: true
        }}
        slotProps={{
          paper: {
            sx: {
              backdropFilter: "none",
              boxShadow: "none",
              backgroundColor: "transparent"
            }
          }
        }}
      >
        {groupedIssues.map((issueId) => (
          <MenuItem
            key={issueId}
            disableGutters
            component="li"
            tabIndex={-1}
            sx={{
              p: 0,
              backgroundColor: "transparent !important",
              borderRadius: 4
            }}
            onClick={() => handleIssueClick(issueId)}
          >
            <DubPlayerCommentButton
              key={issueId}
              dubId={dubId}
              issueId={issueId}
              props={{
                tabIndex: -1,
                sx: {
                  ["&:focus"]: accessibilityUtils.generalFocusVisibleStyle
                },
                id: `btn-${dubId}-${issueId}`
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
